<template>
    <div class="codics-shop-detail-body">
        <!-- <el-page-header style="display: none !important;" class="codics-shop-detail-header" @back="goBack"
            content="详情页面">
        </el-page-header> -->
        <el-carousel class="codics-shop-detail-fm" height="75vw">
            <el-carousel-item v-for="item in (productData.product?.img == '' ? 1 : productData.product?.img.split(','))"
                :key="item">
                <el-empty v-show="productData.product?.img == ''" description="没有介绍图片"></el-empty>
                <el-image :src="'https://cdn.codics.cn/' + item" fit="fill"></el-image>
            </el-carousel-item>
        </el-carousel>
        <div class="codics-shop-detail-marquee">
            <div class="codics-shop-detail-marquee-tip"
                style="height: 100%;width: fit-content;position: relative;float: left;line-height: 50px;padding-right: 15px;margin-right: 15px;">
                用户须知</div>
            <ul class="marquee_list" :class="{ marquee_top: marqueeAnimate }">
                <li v-for="item in marqueeList" :key="item.id" @click="ggDilog = true; nowMarquee = item">
                    {{ item.title }}
                </li>
            </ul>
        </div>
        <!-- <marquee class="codics-admin-home-pmd" @click="ggDilog = true;" style="width: calc(100% - 30px);left: 50%;transform: translateX(-50%);font-size: 14px;border-width: 1px;">{{ '这是一条公告通知消息内容！' }}</marquee> -->
        <el-button class="codics-shop-detail-detail" type="warning" plain @click="xqDilog = true;">卡券产品介绍</el-button>
        <fieldset class="codics-shop-detail-cdk-info">
            <legend>卡券信息</legend>
            <table>
                <tbody>
                    <tr>
                        <td>卡卷类型</td>
                        <td>{{ productData.product?.name }}</td>
                    </tr>
                    <tr>
                        <td>卡券状态</td>
                        <td
                            :style="{ color: productData.state == 0 ? '#333' : (productData.state == 1 ? '#333' : productData.state == 2 ? '#333' : '#333') }">
                            {{ productData.state == 0 ? (orderList.length==0 ? '卡券未使用' : '卡券部分使用') : (productData.state == 1 ? '卡券已冻结' : productData.state
                == 2 ?
                '卡券已使用' : '卡券已销毁') }}&nbsp;<a v-show="productData.state != 0" style="color:#E6A23C;"
                                @click="jdDilog = true;">查看提货进度</a></td>
                    </tr>
                    <tr>
                        <td>卡券编号</td>
                        <td style="max-width: 35vw;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{
                this.$route.params.cdkValue }}</td>
                    </tr>
                    <tr>
                        <td>卡券面值</td>
                        <td>￥{{ productData.product?.oprice }}</td>
                    </tr>
                    <tr>
                        <td>可用次数</td>
                        <td>{{ productData.count }}</td>
                    </tr>
                    <tr>
                        <td>有效期</td>
                        <td>{{ new Date(productData.expires) < new Date() ? '已过期' : (new Date(productData.updatetime) <
                new Date() ? dateDiffFuc(new Date(), new Date(productData.expires)) : dateDiffFuc(new
                    Date(productData.updatetime), new Date(productData.expires))) }}</td>
                    </tr>
                </tbody>
            </table>
        </fieldset>
        <fieldset v-show="orderList.length != 0" class="codics-shop-detail-cdk-info" style="margin-top: 15px;">
            <legend>兑换记录</legend>
            <table>
                <tbody>
                    <tr v-for="(item, index) in orderList" :key="index">
                        <td>第{{ index + 1 }}次兑换</td>
                        <td style="color: #E6A23C;cursor: pointer;"
                            @click="jdDilog = true; orderDetail.id = item.id; getOrderDetailFuc();">查询本次提货进度</td>
                    </tr>
                </tbody>
            </table>
        </fieldset>
        <div class="codics-shop-detail-wyth"
            v-show="productData.state == 0 && new Date(productData.updatetime) <= new Date() && new Date(productData.expires) > new Date()"
            @click="thDilog = true;">
            我要提货</div>
        <div class="codics-shop-detail-wyth" v-show="new Date(productData.updatetime) > new Date()"
            style="background: #CCC;color: #333;">
            <!-- Date.now() + (new Date(productData.updatetime).getTime() - new Date().getTime()) -->
            未到提货时间：{{ timestampToDateFuc(productData.updatetime, true) }}
        </div>
        <div class="codics-shop-detail-wyth" v-show="new Date(productData.expires) <= new Date()"
            style="background: #F78989;color: #FFF;">卡券已经过期</div>
        <!-- <div class="codics-shop-detail-wyth" v-show="new Date(productData.updatetime)>new Date()" @click="thDilog = true;" style="background: #CCC;color: #333;">未到提货时间:<el-statistic :value="Date.now()" time-indices title="🎉商品降价🎉"></el-statistic></div> -->
        <div class="codics-shop-detail-wyth" v-show="productData.state != 0" style="background: #E6A23C;"
            @click="jdDilog = true; getOrderDetailFuc();">查看提货进度</div>
        <el-drawer title="查看详情" :visible.sync="xqDilog" direction="btt" size="calc(100% - 60px)">
            <el-image v-for="item in productData.product?.itimg.split(',')" :key="item"
                style="position: relative;float: left;" :src="'https://cdn.codics.cn/' + item" fit="fill"></el-image>
        </el-drawer>
        <el-drawer title="卡券提货" :visible.sync="thDilog" direction="btt" size="calc(100% - 60px)">
            <!-- <VerifyCode
                style="height: 55px;width: calc(100% - 30px);position: relative;float: left;left: 50%;transform: translateX(-50%);margin-bottom: 5px;"
                :code="thData.pass==null ? '' : thData.pass"></VerifyCode> -->
            <el-input style="width: calc(100% - 30px);left: 50%;transform: translateX(-50%);margin-bottom: 15px;"
                v-model="thData.pass" placeholder="请输入卡券的卡密"></el-input>
            <el-date-picker style="width: calc(100% - 30px);left: 50%;transform: translateX(-50%);margin-bottom: 15px;"
                v-model="thData.time" type="datetime" value-format="timestamp" placeholder="选择发货时间">
            </el-date-picker>
            <el-input style="width: calc(100% - 30px);left: 50%;transform: translateX(-50%);margin-bottom: 15px;"
                v-model="thData.name" placeholder="请输入收件人姓名"></el-input>
            <el-input style="width: calc(100% - 30px);left: 50%;transform: translateX(-50%);margin-bottom: 15px;"
                v-model="thData.phone" type="number" placeholder="请输入收件手机号"></el-input>
            <el-cascader style="width: calc(100% - 30px);left: 50%;transform: translateX(-50%);margin-bottom: 15px;"
                size="large" :options="areaOptions" v-model="addtions" @change="handleChange">
            </el-cascader>
            <el-input style="width: calc(100% - 30px);left: 50%;transform: translateX(-50%);margin-bottom: 15px;"
                type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" placeholder="请输入地址详情" v-model="thData.r">
            </el-input>
            <div class="codics-shop-detail-wyth" @click="exchangeOrder">确定提货</div>
        </el-drawer>
        <el-drawer title="提货进度" :visible.sync="jdDilog" direction="btt" size="calc(100% - 60px)">
            <el-input v-if="wiliuData.length == 0 && orderDetail.pass == null"
                style="width: calc(100% - 30px);left: 50%;transform: translateX(-50%);margin-bottom: 15px;"
                placeholder="请输入密钥查询" v-model="orderDetail.pass" clearable>
            </el-input>
            <el-timeline :reverse="false" style="width: calc(100% - 30px);margin-top: 10px;">
                <el-timeline-item v-for="(activity, index) in wiliuData" :key="index" :timestamp="activity.time"
                    placement="top">
                    <el-card>
                        <h4>{{ activity.context }}</h4>
                        <p>数据更新于 {{ activity.time }}</p>
                    </el-card>
                </el-timeline-item>
            </el-timeline>
            <div v-if="wiliuData.length == 0 && orderDetail.pass == null" class="codics-shop-detail-wyth"
                @click="getOrderDetailFuc">查询进度</div>
        </el-drawer>
        <el-drawer :title="nowMarquee?.title" :visible.sync="ggDilog" direction="btt" size="calc(100% - 60px)">
            <div class="codics-shop-detail-xtgg-content" v-html="nowMarquee?.content"></div>
        </el-drawer>
    </div>
</template>

<script>
import { getCdkInfo, exchangeProduct, getOrderDetail, noticeList } from '@/api';
import { regionData, codeToText, TextToCode } from "element-china-area-data";
import { currentDate, dateDiff, dateToTime, timestampToDate } from '@/utils/myutils';
// import VerifyCode from '@/components/VerifyCode';
export default {
    data() {
        return {
            productData: {},
            xqDilog: false,
            thDilog: false,
            jdDilog: false,
            ggDilog: false,
            areaOptions: regionData,
            selectedOptions: [],
            addtions: {},//存储地址数据
            thData: {
                cdk: this.$route.params.cdkValue,
                pass: this.$route.query.p,
                name: '',
                phone: '',
                p: '',
                c: '',
                a: '',
                r: ''
            },
            wuliuInfo: '',
            orderDetail: {
                pass: this.$route.query.p,
                cdk: this.$route.params.cdkValue,
                id: null
            },
            wiliuData: [],
            marqueeAnimate: false,
            marqueeList: [],
            nowMarquee: {},
            verifyCode: '',
            orderList: []
        }
    },
    mounted() {

        this.getCdkInfoFuc(this.$route.params.cdkValue);

        // setInterval(this.showMarquee, 3000);

    },
    methods: {
        timestampToDateFuc(time, s) {
            return timestampToDate(time, s);
        },
        getDate(t) {
            return currentDate(t);
        },
        goBack() {

            this.$router.push({ name: this.$route.params.path, params: {}, query: {} }, () => { }, () => { });

        },
        async getCdkInfoFuc(cdk) {

            let data = await getCdkInfo({ 'cdk': cdk });

            if (data.code == 200) {

                this.productData = data.data;

                this.orderList = data.orders;

                console.log(data?.orders)

                this.orderDetail.id = data?.orders[data?.orders?.length-1].id

                // console.log(this.productData)

                this.noticeListFuc(data.data.product.sid);

            } else {

                this.$message.error(data.message);

            }

        },
        handleChange(value) {

            value.forEach((item, index) => {

                switch (index) {

                    case 0:

                        this.thData.p = codeToText[item];

                        break;

                    case 1:

                        this.thData.c = codeToText[item];

                        break;

                    case 2:

                        this.thData.a = codeToText[item];

                        break;

                }

            });





        },
        async exchangeOrder() {
            if (this.thData.pass == '' || this.thData.name == '' || this.thData.phone == '' || this.thData.phone.length != 11) {
                this.$message({
                    showClose: true,
                    message: this.thData.pass == '' ? '卡券卡密不能为空！' : (this.thData.name == '' ? '收件人姓名不能为空！' : (this.thData.phone == '' ? '收件手机号不能为空！' : '手机号码格式不正确！')),
                    type: 'warning'
                });
                return true;
            }

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await exchangeProduct(this.thData);

            loading.close();

            if (data.code == 200) {

                this.$message.success(data.message);

                setTimeout(() => {

                    window.location.reload();

                }, 300);

            } else {

                this.$message.error(data.message);

            }
        },
        async getOrderDetailFuc() {

            if (this.orderDetail.cdk == '' || this.orderDetail.pass == '') {

                this.$message.error("卡券跟密码不能为空！");

                return true;

            }

            let data = await getOrderDetail(this.orderDetail);

            if (data.code == 200) {

                switch (data.data) {

                    case 0:

                        console.log("订单未核销！");

                        this.wiliuData = [
                            {
                                time: dateToTime(new Date(data.ctime)),
                                context: '订单正在等待核销！'
                            }
                        ]

                        break;

                    case 1:

                        console.log("订单等待出库！");

                        this.wiliuData = [
                            {
                                time: dateToTime(new Date()),
                                context: '订单等待出库！'
                            },
                            {
                                time: dateToTime(new Date(data.ctime)),
                                context: '订单已核销！'
                            }
                        ]

                        break;

                    case 3:

                        this.wiliuData = [
                            {
                                time: dateToTime(new Date(data.ctime)),
                                context: '订单已被冻结！'
                            }
                        ]

                        break;

                    case 4:

                        this.wiliuData = [
                            {
                                time: dateToTime(new Date(data.ctime)),
                                context: '订单已被关闭！'
                            }
                        ]

                        break;

                    default:

                        let t = JSON.parse(data.data.body).data;

                        this.wiliuData = [
                            {
                                time: t[t.length - 1].time,
                                context: '订单已出库！'
                            },
                            {
                                time: dateToTime(new Date(data.ctime)),
                                context: '订单已核销！'
                            }
                        ]

                        this.wiliuData = t.concat(this.wiliuData);

                        break;

                }

            } else {

                this.$message.error(data.message);

            }

        },
        showMarquee() {
            this.marqueeAnimate = true;
            let _this = this;
            setTimeout(() => {
                _this.marqueeList.push(_this.marqueeList[0]);
                _this.marqueeList.shift();
                _this.marqueeAnimate = false;
            }, 500)
        },
        async noticeListFuc(sid) {
            let data = await noticeList({ 'sid': sid });
            if (data.code == 200) {
                this.marqueeList = data.data;
            } else {
                this.$message.error(data.message);
            }
        },
        dateDiffFuc(d1, d2) {
            return dateDiff(d1, d2);
        }
    },
    components: {
        // VerifyCode
    }
}
</script>

<style>
.codics-shop-detail-body {
    height: 100%;
    width: 100%;
    position: relative;
    float: left;
    background: #FFF;
    overflow-y: scroll;
    overflow-x: hidden;
    box-sizing: border-box;
    padding-bottom: 65px;
}

.codics-shop-detail-header {
    height: 60px;
    width: 100%;
    position: relative;
    float: left;
    line-height: 60px !important;
    border-bottom: 1px solid #E9E9E9;
    padding-left: 20px;
}

.codics-shop-detail-fm {
    height: 75vw;
    width: 100%;
    position: relative;
    float: left;
    margin-bottom: 15px;
}

.codics-shop-detail-detail {
    width: calc(100% - 30px);
    position: relative;
    float: left;
    left: 15px;
    margin-bottom: 10px !important;
}

.codics-shop-detail-cdk-info {
    height: fit-content;
    width: calc(100% - 30px);
    box-sizing: border-box;
    position: relative;
    float: left;
    left: 15px;
    border: 1px solid #DCDFE6;
    padding-bottom: 0px;
    font-size: 14px !important;
}

.codics-shop-detail-cdk-info table {
    line-height: 50px;
    width: 100%;
    position: relative;
    margin-top: 15px;
    margin-bottom: 15px;
    box-sizing: border-box;
}

.codics-shop-detail-cdk-info table,
.codics-shop-detail-cdk-info td,
.codics-shop-detail-cdk-info th {
    border-collapse: collapse;
    border-spacing: 10px;
    border: 1px solid #DDD;
    min-width: 45px;
}

.codics-shop-detail-cdk-info td {
    padding-left: 10px;
}

.codics-shop-detail-cdk-info table tr:nth-child(even) {
    background: #f8f8f8;
}

.codics-shop-detail-wyth {
    height: 50px;
    width: 100%;
    position: fixed;
    bottom: 0;
    background: #F53033;
    line-height: 50px;
    font-size: 14px;
    color: #FFF;
    text-align: center;
}

.codics-shop-detail-marquee {
    height: 50px;
    width: calc(100% - 30px);
    position: relative;
    float: left;
    left: 50%;
    transform: translateX(-50%);
    line-height: 50px;
    padding: 0 15px 0 15px;
    background-color: #f8f8f8;
    border-left: 1px solid #e6a23c;
    box-sizing: border-box;
    margin-bottom: 15px;
    font-size: 14px;
    overflow: hidden;
}

.codics-shop-detail-marquee-tip::after {
    content: '';
    height: 20px;
    width: 1px;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    right: 0;
    background: #CCC;
}

.codics-shop-detail-marquee ul,
li {
    margin: 0;
    padding: 0;
    /* display: flex; */
    box-sizing: border-box;
    list-style-type: none;
    overflow: hidden;
}

.codics-shop-detail-xtgg-content {
    height: fit-content;
    width: 100%;
    position: relative;
    float: left;
    box-sizing: border-box;
    padding: 0 20px 0 20px;
    font-size: 14px;
    color: #999;
    line-height: 2;
    white-space: break-spaces;
}

.codics-shop-detail-wyth span {
    height: 50px;
    bottom: 0;
    line-height: 50px !important;
    font-size: 14px;
    color: #333;
    text-align: center;
    font-size: 14px !important;
}

.codics-shop-detail-marquee::after {
    content: '点击查看';
    height: 100%;
    width: fit-content;
    padding: 0 20px 0 20px;
    position: absolute;
    top: 0;
    right: 0;
    line-height: 50px;
    text-align: center;
    color: #e6a23c;
    pointer-events: none;
}
</style>