<template>
    <div class="codics-admin-product-brand-body">
        <div class="codics-admin-product-brand-control">
            <el-button class="codics-admin-product-brand-control-search" type="success" icon="el-icon-plus"
                @click="addProductDialogVisibility = true;">添加</el-button>
            <!-- 添加表单 -->
            <el-dialog title="添加商品卡密" :visible.sync="addProductDialogVisibility" width="80%">
                <el-form :model="addForm">
                    <el-form-item label="卡密状态" :label-width="formLabelWidth">
                        <el-select v-model="addForm.sta" placeholder="请选择卡密状态">
                            <el-option v-for="item in cdkState" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="有效日期" :label-width="formLabelWidth">
                        <el-date-picker v-model="addTimes" type="datetimerange" range-separator="至"
                            :picker-options="pickerOptions" start-placeholder="开始日期" end-placeholder="结束日期"
                            @change="datePicker">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="可用次数" :label-width="formLabelWidth">
                        <el-input-number v-model="addForm.cot" :min="1" :max="10" label="卡密可用次数"></el-input-number>
                    </el-form-item>
                    <el-form-item label="对应商品" :label-width="formLabelWidth">
                        <el-select v-model="addForm.sid" clearable filterable remote reserve-keyword
                            placeholder="请输入关键词" :remote-method="queryProductData" :loading="shopLoad">
                            <el-option v-for="item in productOptions" :key="item.id" :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="生成数量" :label-width="formLabelWidth">
                        <el-input-number v-model="addForm.num" :min="1" :max="1000" label="卡密生成数量"></el-input-number>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="addProductDialogVisibility = false">取 消</el-button>
                    <el-button type="primary" @click="addProductCdkFuc">确 定</el-button>
                </div>
            </el-dialog>
            <el-input class="codics-admin-product-brand-control-input" placeholder="请输入搜索内容" v-model="searchTxt"
                clearable>
                <el-button slot="append" icon="el-icon-search"></el-button>
            </el-input>
            <el-button class="codics-admin-product-brand-control-search" type="info" icon="el-icon-s-check" plain
                @click="lyFuc">领用</el-button>
            <el-dialog title="领用券码" :visible.sync="lyDialogVisible" width="80%">
                <el-select v-model="selectUserData" style="width: 100%;margin-bottom: 10px;" filterable remote
                    reserve-keyword placeholder="请输入用户账号/昵称/邮箱/手机号" :remote-method="searchUsersFuc"
                    :loading="userLoading" clearable>
                    <el-option v-for="item in usersOptions" :key="item.id" :label="item.nickname" :value="item.id">
                    </el-option>
                </el-select>
                <el-input v-model="lyNote" placeholder="请输入领用备注" style="margin-bottom: 10px;"></el-input>
                <el-tag v-for="tag in selectData" :key="tag.cdk" closable type="info"
                    style="margin-bottom: 10px;margin-right: 10px;user-select: none;" @close="handleUserTagClose(tag)">
                    {{ tag.cdk }}
                </el-tag>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="lyDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="cardVoucherFuc">确 定</el-button>
                </div>
            </el-dialog>
            <el-popconfirm :title="'您确定要删除当前选中的' + selectData.length + '条数据吗？'" @confirm="deleteCdksFuc">
                <el-button class="codics-admin-product-brand-control-search" slot="reference" type="danger"
                    icon="el-icon-delete">批量删除</el-button>
            </el-popconfirm>
            <!-- <el-popconfirm :title="'您确定要冻结当前选中的' + selectData.length + '条数据吗？'" @confirm="deleteCdksFuc">
                <el-button class="codics-admin-product-brand-control-search" slot="reference" type="primary"
                    icon="el-icon-lock">冻结</el-button>
            </el-popconfirm>
            <el-popconfirm :title="'您确定要封禁当前选中的' + selectData.length + '条数据吗？'" @confirm="deleteCdksFuc">
                <el-button class="codics-admin-product-brand-control-search" slot="reference" type="info"
                    icon="el-icon-document-delete">封卡</el-button>
            </el-popconfirm> -->
            <el-dropdown style="position: relative;float: left;margin-right: 20px;" split-button type="primary" @click="handleClick">
                批量冻结
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>批量封卡</el-dropdown-item>
                    <el-dropdown-item>刷新数据</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
            <el-button class="codics-admin-product-brand-control-search" icon="el-icon-refresh">刷新</el-button>
            <div
                style="height: 40px;width: fit-content;position: relative;float: left;text-align: center;font-size: 14px;">
                <a href="https://katihuo.com/#/shop/exchange" style="line-height: 40px;"
                    target="_blank">兑换地址：https://katihuo.com/shop/exchange</a>
            </div>
            <el-switch v-model="passState" style="top: 10px;margin-left: 20px;" active-text="带密码" inactive-text="不带密码"
                @change="changePassState">
            </el-switch>
            <el-button class="codics-admin-product-brand-control-search" style="float: right !important;margin-right: 0 !important;"
                icon="el-icon-download" @click="tableToExcel">导出数据</el-button>
        </div>
        <el-table stripe class="codics-admin-product-brand-table" :data="tableData" border style="width: 100%"
            @selection-change="handleSelectionChange" ref="userTable">
            <el-table-column type="selection" width="40">
            </el-table-column>
            <el-table-column prop="id" label="ID" width="80">
            </el-table-column>
            <el-table-column prop="cdk" label="兑换码" width="150">
            </el-table-column>
            <el-table-column prop="pass" label="密钥" width="80">
            </el-table-column>
            <el-table-column prop="cdk" label="二维码" width="100">
                <template slot-scope="scope">
                    <el-image :src="scope.row?.qrcode == null ? '' : scope.row?.qrcode" fit="fill"></el-image>
                </template>
            </el-table-column>
            <el-table-column prop="state" label="状态" width="70"
                :filters="[{ text: '可用', value: 0 }, { text: '冻结', value: 1 }, { text: '已用', value: 2 }, { text: '销毁', value: 3 }]"
                :filter-method="stateFilter" filter-placement="bottom-end">
                <template slot-scope="scope">
                    <el-tag
                        :type="scope.row.state == 0 ? 'success' : (scope.row.state == 1 ? 'info' : (scope.row.state == 2 ? 'warning' : 'danger'))"
                        disable-transitions>{{ scope.row.state == 0 ? '可用' :
                    (scope.row.state == 1 ? '冻结' : (scope.row.state == 2 ? '已用' : '销毁')) }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="expires" label="有效期" width="180">
                <template slot-scope="scope">
                    {{ dateDiffFuc(new Date() >= new Date(scope.row.updatetime) ? new Date() : new
                    Date(scope.row.updatetime), new Date(scope.row.expires)) }}
                </template>
            </el-table-column>
            <el-table-column prop="count" label="可用次数" width="80">
            </el-table-column>
            <el-table-column prop="updatetime" label="开始时间" width="180">
                <template slot-scope="scope">
                    {{ new Date() >= new Date(scope.row.updatetime) ? "现在" : scope.row.updatetime }}
                </template>
            </el-table-column>
            <el-table-column prop="expires" label="截至时间" width="180">
            </el-table-column>
            <el-table-column prop="sid" label="商品" width="80">
            </el-table-column>
            <el-table-column align="right" label="操作" min-width="150">
                <template slot-scope="scope">
                    <el-popconfirm title="冻结后卡密将无法完成兑换操作，需解冻后才可正常使用，确定要冻结当前券码吗？">
                        <el-button size="mini" slot="reference" style="margin-right: 10px;"
                            @click="freezeCdk(scope.$index, scope.row)">冻结</el-button>
                    </el-popconfirm>
                    <el-popconfirm title="您确定要对该卡券进行封卡操作吗？封卡后该卡密将彻底被作废掉！">
                        <el-button size="mini" slot="reference" style="margin-right: 10px;"
                            @click="banCdk(scope.$index, scope.row)">封卡</el-button>
                    </el-popconfirm>
                    <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
                        style="margin-right: 10px;">编辑</el-button>
                    <el-popconfirm title="这是一段内容确定删除吗？">
                        <el-button size="mini" type="danger" slot="reference"
                            @click="handleDelete(scope.$index, scope.row);">删除</el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="codics-admin-product-brand-table-page" @size-change="handleSizeChange"
            @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 30, 40]"
            :page-size="10" layout="total, sizes, prev, pager, next, jumper" :total="maxSize" :pager-count="5">
        </el-pagination>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { queryProduct, addProductCdks, getCdkList, reloadCdks, deleteCdks, searchUsers, cardVoucher } from '@/api';
import { exportExcel, dateDiff } from "@/utils/myutils";
import QRCode from 'qrcode';
import { getCookie, setCookie, delCookie } from '@/utils/mycookie';

export default {
    data() {
        return {
            cdkState: [
                {
                    value: 0,
                    label: '可用'
                },
                {
                    value: 1,
                    label: '冻结'
                },
                {
                    value: 2,
                    label: '已用'
                },
                {
                    value: 3,
                    label: '销毁'
                }
            ],
            shopLoad: false,
            productOptions: [],
            lyDialogVisible: false,
            addProductDialogVisibility: false,
            addForm: {
                sta: 0,
                exp: new Date().getTime(),
                cot: 1,
                sid: null,
                num: 1
            },
            addTimes: [],
            formLabelWidth: '120px',
            searchType: '名称',
            searchTxt: '',
            currentPage: 1,
            tableData: [],
            multipleSelection: [],
            maxSize: 0,
            dataSize: 10,
            selectData: [],
            lyOptions: [],
            usersOptions: [],
            userLoading: false,
            selectUserData: '',
            passState: getCookie("admin-product-cdk-pass-state") != null,
            lyNote: '',
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        end.setTime(end.getTime() + 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                },
                {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        end.setTime(end.getTime() + 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                },
                {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        end.setTime(end.getTime() + 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                },
                {
                    text: '最近一年',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        end.setTime(end.getTime() + 3600 * 1000 * 24 * 365);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            }
        }
    },
    mounted() {

        this.getCdksData(this.currentPage, this.dataSize);

    },
    methods: {
        async creatQrCode(i, QRlink) {   // QRlink是二维码的内容
            let opts = {
                errorCorrectionLevel: "L",//容错级别
                type: "image/png",//生成的二维码类型
                quality: 0.3,//二维码质量
                margin: 1,//二维码留白边距
                width: 100,//宽
                height: 100,//高
                text: QRlink,//二维码内容
                color: {
                    dark: "#000",//前景色
                    light: "#fff"//背景色
                }
            };
            await QRCode.toDataURL(QRlink, opts, (err, url) => {
                if (err) throw err
                //将生成的二维码路径复制给data的QRImgUrl
                // console.log(url)
                i['qrcode'] = url;
            })
        },
        async getCdksData(pge, psz) {

            let data = await getCdkList({ "pge": pge, "psz": psz });

            if (data.code == 200) {

                this.tableData = data.data;

                this.changePassState();

                this.maxSize = data.count;

            } else {

                this.$message.error(data.message);

            }

        },
        handleSizeChange(val) {
            this.dataSize = val;
            this.getCdksData(this.currentPage, val);
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getCdksData(val, this.dataSize);
        },
        stateFilter(v, r, c) {

            return r.state === v;

        },
        handleSubCat(value) {
            // 获取value值
            this.addForm.p = value.length == 0 ? 0 : value[value.length - 1];
            // 获取label 值
            // console.log(this.$refs["refSubCat"].getCheckedNodes()[0].label)
        },
        async addProductCdkFuc() {

            if (this.addForm.exp - new Date().getTime() < 0) {

                this.$notify({
                    title: '警告',
                    message: '卡密有效期不能小于1天！',
                    type: 'warning'
                });

                return true;

            }

            if (this.addForm.start < new Date().getTime() - 30000) {

                this.$notify({
                    title: '警告',
                    message: '卡密开始使用时间不能早于今天！',
                    type: 'warning'
                });

                return true;

            }

            if (this.addForm.sid == null || this.addForm.sid == '') {

                this.$notify({
                    title: '警告',
                    message: '卡密绑定商品不能为空！',
                    type: 'warning'
                });

                return true;

            }

            let data = await addProductCdks(this.addForm);

            if (data.code == 200) {

                this.$message({
                    message: data.count + '条卡密生成成功！',
                    type: 'success'
                });

                setTimeout(() => {

                    window.location.reload();

                }, 300);

            } else {

                this.$message.error(data.message);

            }

        },
        async queryProductData(query) {
            if (query !== '') {
                this.shopLoad = true;
                let data = await queryProduct({ key: query });
                this.shopLoad = false;
                if (data.code == 200) {

                    this.$message({
                        message: '查询商品成功！',
                        type: 'success'
                    });

                    this.productOptions = data.data;

                } else {

                    this.$message.error(data.message);

                    this.productOptions = [];

                }
            } else {
                this.productOptions = [];
            }
        },
        async handleDelete(index, row) {
            console.log(index, row)
        },
        async handleFree(index, row) {
            console.log(index, row)
        },
        async handleBan(index, row) {
            console.log(index, row)
        },
        handleSelectionChange(val) {
            this.selectData = val;
        },
        async deleteCdksFuc() {

            if (this.selectData.length == 0) {

                this.$message({
                    showClose: true,
                    message: '请先选中要删除的数据！',
                    type: 'warning'
                });

                return true;

            }

            let t = [];

            this.selectData.forEach(item => {

                t[t.length] = item.id;

            });
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            let data = await deleteCdks({ ids: t });
            loading.close();
            if (data.code == 200) {
                this.$message({
                    message: data.data + '条数据删除成功！',
                    type: 'success'
                });
                setTimeout(() => {

                    window.location.reload();

                }, 300);
            } else {
                this.$message.error(data.message);
            }
        },
        tableToExcel() {
            if (this.selectData.length == 0) {
                this.$message({
                    message: '请至少选中一条数据再进行该操作！',
                    type: 'warning'
                });
                return true;
            }
            const now = new Date();
            const year = now.getFullYear(); // 获取当前年份
            const month = now.getMonth() + 1; // 获取当前月份，注意getMonth()返回的是0-11，所以需要加1
            const day = now.getDate(); // 获取当前日期
            let title = ['序号', '券码', '密钥', "状态", "有效期", "商品", '二维码'];
            let filter = ['count', 'createby', 'createtime', 'updateby', 'updatetime', 'product'];  //不导出的字段
            exportExcel(this.selectData, '券码数据_' + year + month + day, title, filter);
        },
        lyFuc() {

            if (this.selectData.length == 0) {

                this.$message({
                    showClose: true,
                    message: '请先选中要领用的券码！',
                    type: 'warning'
                });

                return true;

            }

            this.lyDialogVisible = true;

        },
        async searchUsersFuc(query) {
            if (query !== '') {
                this.userLoading = true;
                let data = await searchUsers({ key: query });
                this.userLoading = false;
                if (data.code == 200) {
                    this.usersOptions = data.data;
                } else {
                    this.$message.error(data.message);
                }
            } else {
                this.usersOptions = [];
            }
        },
        handleUserTagClose(tag) {
            this.$refs.userTable.selection.splice(this.$refs.userTable.selection.indexOf(tag), 1);
            this.selectData = this.$refs.userTable.selection;
            if (this.selectData.length == 0) {
                this.lyDialogVisible = false;
            }
        },
        async cardVoucherFuc() {

            if (this.selectData.length == 0 || this.selectUserData == '') {

                this.$message({
                    showClose: true,
                    message: '请先选中要领用的账户或者卡券！',
                    type: 'warning'
                });

                return true;

            }

            let t = [];

            this.selectData.forEach(item => {

                t[t.length] = item.id;

            });
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            let data = await cardVoucher({ ids: t, uid: this.selectUserData, remark: this.lyNote });
            loading.close();
            if (data.code == 200) {
                this.$message({
                    message: data.data + '条数据领用成功！',
                    type: 'success'
                });
                setTimeout(() => {
                    window.location.reload();
                }, 300);
            } else {
                this.$message.error(data.message);
            }
        },
        datePicker(t) {
            console.log(t)
            this.addForm.start = new Date(t[0]).getTime();
            this.addForm.exp = new Date(t[1]).getTime();
        },
        dateDiffFuc(d1, d2) {
            return dateDiff(d1, d2);
        },
        changePassState(a, b, c) {
            if (a) {
                setCookie('admin-product-cdk-pass-state', '1');
            } else {
                delCookie('admin-product-cdk-pass-state');
            }
            // getCookie("admin-product-cdk-pass-state")!=null
            for (var i in this.tableData) {
                this.creatQrCode(this.tableData[i], 'https://katihuo.com/#/shop/exchange?k=' + this.tableData[i]?.cdk + (this.passState ? ('&p=' + this.tableData[i]?.pass) : ''))//u=' + this.userInfo?.user.id + '&
            }
        }
    },
    computed: {
        ...mapState(['userInfo'])
    },
}
</script>

<style>
/* 后台管理端主页 */
.codics-admin-product-brand-body {
    height: fit-content;
    width: 100%;
    position: relative;
    float: left;
    background: #FFF;
    box-sizing: border-box;
    padding: 20px;
}

/* 后台管理页面主页 */
.codics-admin-product-brand-control {
    height: fit-content;
    width: 100%;
    position: relative;
    float: left;
}

/* 后台管理页面主页控制栏 */
.codics-admin-product-brand-control-input {
    width: 200px !important;
    position: relative;
    float: left;
    margin-right: 20px;
    margin-bottom: 20px;
}

/* 后台管理页面控制栏搜索按钮 */
.codics-admin-product-brand-control-search {
    position: relative;
    float: left;
    margin-left: 0px !important;
    margin-right: 20px !important;
}

/* 表格控件 */
.codics-admin-product-brand-table {
    height: fit-content;
    width: 100% !important;
    position: relative;
    float: left;
    margin-bottom: 20px;
}

/* 表格分页控件 */
.codics-admin-product-brand-table-page {
    position: relative;
    float: right;
    right: 0;
}
</style>